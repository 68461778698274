'use strict';

var Backbone = require('backbone');

module.exports = Backbone.Collection.extend({
  url: function(){
    return RSApp.url + 'getFiles.php';
  },
  model: require('../models/file'),
  comparator: function compareName(model){
       return model.get('name');
  }
});
