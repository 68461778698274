// Classe de contrôleur étendu
// ===========================

'use strict';

var _ = require('underscore');
var Backbone = require('backbone');
var helpers = require('lib/view_helper');

module.exports = Backbone.View.extend({
  initialize: function() {
    _.bindAll(this, 'template', 'getRenderData', 'render', 'afterRender');
    //_.defer(this.afterInitialize);
  },

  template: function() {},

  getRenderData: function() {
    return this.model && this.model.toJSON ? this.model.toJSON() : this.model;
  },

  render: function() {

    this.$el.html(this.renderTemplate(this.getRenderData()));

    _.defer(this.afterRender);
    return this;
  },

  renderTemplate: function renderTemplate(obj, tmpl) {
    tmpl = tmpl || this.template;
    var presenter = $.extend({}, helpers, obj);

    return tmpl(presenter);
  },

  afterRender: function() {

  }
});
