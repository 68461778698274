"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
var moment = require('moment');

module.exports = View.extend({
  template: require('./templates/devices_form_name_editor'),
  initialize: function(type){
    _.bindAll(this, 'template', 'render');
  }
});