var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (color, name, url) {
buf.push("<div class=\"checkbox col-md-2 col-xs-6\"><label><input type=\"checkbox\"" + (jade.attr("value", "" + (url) + "", true, false)) + " class=\"checkTrace\"/><span" + (jade.attr("style", "background-color:" + (color) + ";", true, false)) + " class=\"colorTrace\"></span><span class=\"col-md-9\">" + (jade.escape(null == (jade_interp = name) ? "" : jade_interp)) + "</span></label></div>");}.call(this,"color" in locals_for_with?locals_for_with.color:typeof color!=="undefined"?color:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"url" in locals_for_with?locals_for_with.url:typeof url!=="undefined"?url:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}