'use strict';


var Backbone = require('backbone');
var application = require('./Application');

module.exports = Backbone.Router.extend({
  initialize: function(options){
    RSApp.mediator.publish('showMask');
    RSApp.mediator.publish('showLoader');


  },
  routes: {
    '': 'map',
    '/admin': 'admin',
  },
  //display list
  map: function() {

    RSApp.mediator.publish('hideMask');
    var MapView = require('views/map_view');
    var TraceCheckView = require('views/controle_map_view');
    //init control view
    var traceControlView = new TraceCheckView();
    //init map
    var mapView = new MapView();

    $('#GPSApp').empty().append(mapView.render().el).append(traceControlView.render().el);

  },

  admin: function() {
    var TraceCheckView = require('views/controle_map_view');

    //init control view
    var traceControlView = new TraceCheckView();
    //init map
    var mapView = new MapView();

    $('#GPSApp').empty().append(mapView.render().el).append(traceControlView.render().el);

  }
});
