var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (t) {
buf.push("<div class=\"panel-heading\">" + (jade.escape(null == (jade_interp = t('Select the tracker to display on map  ')) ? "" : jade_interp)) + "<span style=\"background-color:green;color: white;\">" + (jade.escape(null == (jade_interp = "<5m") ? "" : jade_interp)) + "</span><span>" + (jade.escape(null == (jade_interp = "  ") ? "" : jade_interp)) + "</span><span style=\"background-color:yellow;\">" + (jade.escape(null == (jade_interp = "<10m") ? "" : jade_interp)) + "</span><span>" + (jade.escape(null == (jade_interp = "  ") ? "" : jade_interp)) + "</span><span style=\"background-color:orange;\">" + (jade.escape(null == (jade_interp = "<1h") ? "" : jade_interp)) + "</span><span>" + (jade.escape(null == (jade_interp = "  ") ? "" : jade_interp)) + "</span><span style=\"background-color:red; color: white;\">" + (jade.escape(null == (jade_interp = "<2h") ? "" : jade_interp)) + "</span><span>" + (jade.escape(null == (jade_interp = "  ") ? "" : jade_interp)) + "</span><span style=\"background-color: black; color: white;\">" + (jade.escape(null == (jade_interp = ">2h") ? "" : jade_interp)) + "</span></div><div class=\"panel-body\"><div id=\"selectTracker\"></div></div>");}.call(this,"t" in locals_for_with?locals_for_with.t:typeof t!=="undefined"?t:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}