var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (t) {
buf.push("<div class=\"panel-heading\">" + (jade.escape(null == (jade_interp = t('Select the trace to display on map')) ? "" : jade_interp)) + "</div><div class=\"panel-body\"><div id=\"selectTrace\"></div></div>");}.call(this,"t" in locals_for_with?locals_for_with.t:typeof t!=="undefined"?t:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}