"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
var ViewDevicesFormNameEditor = require('./devices_form_name_editor_view');

module.exports = View.extend({
  id: 'control-map',

  initialize: function(type){
    _.bindAll(this, 'template', 'getRenderData', 'render', 'showDevices');
    RSApp.mediator.subscribe('showDevices', this.showDevices);
  },

  afterRender: function() {
    $('#control-map').empty();
  },

  showDevices: function () {
    $('#devicesFormNameEditor').empty();

    RSApp.Devices.each(function(device, k) {
      var devicesFormNameEditor = new ViewDevicesFormNameEditor({model: device});
      $('#devicesFormNameEditor').append(devicesFormNameEditor.render().el);
    });
  }
});
