"use strict";

var _ = require('underscore');
var Backbone = require('backbone');


var File = Backbone.Model.extend({
  url: function(){
    return false;
  },
  default:{
    name: '',
    url: ''
  }
});

module.exports = File;
