'use strict';

var Backbone = require('backbone');

module.exports = Backbone.Collection.extend({
  url: function() {
    return RSApp.url + 'getDevices.php';
  },
  model: require('../models/device')
});
