"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
var str_to_color = require('string_to_color');
var elemView = require('./trace_check_view');

module.exports = View.extend({
  className: 'panel panel-default',
  template: require('./templates/traces_checkbox_view'),
  events: {
  },
  initialize: function(){
    _.bindAll(this, 'template', 'getRenderData', 'render', 'afterRender');
    RSApp.mediator.subscribe('showTrace', this.render);
  },
  afterRender: function(){

    $('#control-map').append(this.el);

    this.collection = RSApp.Files;
    //sort by alpha
    this.collection.sort();

    var tpl;
    $('#selectTrace').empty();
    this.collection.each(function(v, k){

      //generate color
      //v.attributes.color = '#'+Math.floor(Math.random()*16777215).toString(16);
      v.attributes.color = '#' + str_to_color(v.attributes.name, 12);

      var trace = new elemView({model: v});

      $('#selectTrace').append(trace.render().el);

    });

  }

});
