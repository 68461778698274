var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (contact, name) {
buf.push("<div class=\"form-group\"><label" + (jade.attr("for", "" + (name) + "", true, false)) + " class=\"col-sm-4 control-label\">" + (jade.escape(null == (jade_interp = name + ': ') ? "" : jade_interp)) + "</label><div class=\"col-sm-6\"><input type=\"text\"" + (jade.attr("id", "" + (name) + "", true, false)) + (jade.attr("name", "" + (name) + "", true, false)) + (jade.attr("value", "" + (contact) + "", true, false)) + " maxlength=\"20\" class=\"form-control\"/></div></div>");}.call(this,"contact" in locals_for_with?locals_for_with.contact:typeof contact!=="undefined"?contact:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}