'use strict';

//application en global
window.RSApp = require('lib/Application');

$(document).ready(function () {

    RSApp.init();

});
