"use strict";

var _ = require('underscore');
var Backbone = require('backbone');
var Device = Backbone.Model.extend({
  url: function(){
    return false;
  },
  default:{
    id: '',
    name: '',
    contact: '',
    category: ''
  }
});

module.exports = Device;