'use strict';

var Backbone = require('backbone');

module.exports = Backbone.Collection.extend({
  url: function(){
    return RSApp.url + 'getPositions.php';
  },
  model: require('../models/position'),
  // parse: function (data) {

  //   if(data.error === 1){
  //     //display error alert
  //     RSApp.mediator.publish('display_msg', 'error', data.error_message);
  //     return false;
  //   }

  //   return data.data;
  //   }
});
