"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');

module.exports = View.extend({
  template: require('./templates/map_view'),
  afterRender: function(){
    RSApp.map = new google.maps.Map($('#map-canvas').get(0), RSApp.mapOptions);

    var traceControl = require('./controle_trace_view');
    var TraceView = new traceControl();
    TraceView.render();

    var positionController = require('./controle_position_view');
    var PositionController = new positionController();
    PositionController.render();
  }
});
