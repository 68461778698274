"use strict";


var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
// var helpers = require('../lib/view_helper');
// var elemView = require('./elem_list_view');

module.exports = View.extend({
  id: 'control-map',
  events: {
  },
  afterRender: function(){
    var traceControl = require('./controle_trace_view');
    var TraceView = new traceControl();
  }

});
