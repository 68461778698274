"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');

module.exports = View.extend({
  template: require('./templates/trace_check'),
  events: {
    'change .checkTrace': 'launchTrace',
    'click .centerTo': 'centerTo'
  },

  initialize: function(type){
    _.bindAll(this, 'template', 'getRenderData', 'render', 'afterRender', 'getFile', 'setTrace');
  },

  centerTo: function(evt){
    evt.preventDefault();
    RSApp.map.fitBounds(RSApp.poly[this.model.attributes.name].bounds);
  },

  launchTrace: function(evt){
    if ($(evt.currentTarget).is(':checked') === true){
      //if trace not exist/ get the xml by ajax
      if(RSApp.poly[this.model.attributes.name] === undefined){
       this.getFile();
      //if exist set on the map
     }
     else{
        RSApp.poly[this.model.attributes.name].setMap(RSApp.map);
         _.each(RSApp.poly[this.model.attributes.name].wpt, function(v, k){

         v.setMap(RSApp.map);
        });
        RSApp.map.fitBounds(RSApp.poly[this.model.attributes.name].bounds);
     }
    //remove from map
    }
    else{
      RSApp.poly[this.model.attributes.name].setMap(null);

      _.each(RSApp.poly[this.model.attributes.name].wpt, function(v, k){
        v.setMap(null);
      });
    }
  },
  //set trace to map
  setTrace : function setTrace(xml){
  var points = [];
  var that = this;

  var bounds = new google.maps.LatLngBounds ();

  //each the trkpt
  $(xml).find("trkpt").each(function() {

    var lat = $(this).attr("lat");
    var lon = $(this).attr("lon");
    var p = new google.maps.LatLng(lat, lon);
    points.push(p);
    bounds.extend(p);

  });
  //create the trace
  var name = that.model.attributes.name;
  RSApp.poly[name] = new google.maps.Polyline({
    // use your own style here
    path: points,
    strokeColor: that.model.attributes.color,
    strokeOpacity: 0.9,
    strokeWeight: 3
  });

  RSApp.poly[name].wpt = {};
  RSApp.poly[name].bounds = bounds;

  //create wpt point
  $(xml).find("wpt").each(function(e, k) {

    var lat = $(this).attr("lat");
    var lon = $(this).attr("lon");
    var p = new google.maps.LatLng(lat, lon);
    var name_wpt = $(this).find('name').text();

    //create point for wpt
    RSApp.poly[name].wpt[name_wpt + '-' + e] = new RichMarker({
              position: p,
              flat: true,
              title: name_wpt,
              map: RSApp.map,
              content: '<div class="markerPerso markerPersoWP" style="background-color:yellow">' + name_wpt + '<div class="center-caret"><i class="fa-caret-down fa" style="color:yellow" ></i></div></div>'
              });

  });

  RSApp.poly[name].setMap(RSApp.map);

  // fit bounds to track
  RSApp.map.fitBounds(RSApp.poly[name].bounds);
},
getFile: function (){
  var that = this;
  //get the xml files
  var url = RSApp.url + that.model.attributes.url;

  $.ajax({
  type: "GET",
  url: url,
  dataType: "xml",

  success: function(xml) {
    that.setTrace(xml);
  }

  });

}
});
