"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
var moment = require('moment');

module.exports = View.extend({
  template: require('./templates/tracker_check'),
  events: {
    'change .checkTracker': 'launchTracker',
    'click .centerTo': 'centerTo'
  },

  initialize: function(type){
    this.is_checked = $(this.el).find('.checkTracker').is(':checked');
    _.bindAll(this, 'template', 'getRenderData', 'render', 'afterRender');
  },

  afterRender: function(){
    if(this.is_checked === true){
      //if checked before udpate -> check
      $(this.el).find('.checkTracker').prop('checked', 'checked');
      //update the tracker on map
      this.displayTracker();
    }
  },

  centerTo: function(evt){
    evt.preventDefault();
    RSApp.map.setCenter(RSApp.mark[this.model.attributes.id].position);
  },

  launchTracker: function(evt){
    if ($(evt.currentTarget).is(':checked')) {
      RSApp.mediator.publish('TrackerAddCheck', this.model.attributes.id);
      this.displayTracker();
      RSApp.map.setCenter(RSApp.mark[this.model.attributes.id].position);
    }
    else {
      RSApp.mediator.publish('TrackerRemoveCheck', this.model.attributes.id);
      RSApp.mark[this.model.attributes.id].setMap(null);
    }
  },

  displayTracker: function() {
    if (RSApp.mark[this.model.attributes.id] !== undefined) {
      RSApp.mark[this.model.attributes.id].setMap(null);
    }

    var position = this.model.attributes.positions[0];
    var id = this.model.attributes.id;
    var speed = position.speed || 0;
    speed = Math.round(speed * 1.852);
    var dt = moment(this.model.attributes.positions[0].time);
    var orientation = (position.course !== '0' ? '<span class="glyphicon glyphicon-arrow-up" style="-ms-transform:rotate(' + Math.round(position.course) + 'deg);-webkit-transform:rotate(' + Math.round(position.course) + 'deg);transform:rotate(' + Math.round(position.course) + 'deg)"></span>' : '');

    RSApp.mark[id] = new RichMarker({
      position: new google.maps.LatLng(position.latitude, position.longitude),
      flat: true,
      content: '<div class="markerPerso" style="background-color:' + this.model.attributes.color +'">' +
                this.model.attributes.name + ' <br />' +
                (speed > 0  ?  orientation + ' ' + speed + 'k' : '' ) +
                '<div class="center-caret"><i class="fa fa-caret-down fa-sm" style="color: '+ this.model.attributes.color +';"></i></div></div>'
    });

    RSApp.mark[id].setMap(RSApp.map);

     //create window infos
    RSApp.infoWindow[id] = new google.maps.InfoWindow({
      content: this.model.attributes.name +
               (this.model.attributes.contact ? '<br />'+this.model.attributes.contact + ' ' : '') +
               '<br />Date: ' + dt.format('DD-MM-YYYY HH:mm:ss') +
               '<br />Latitude: '+ position.latitude.substr(0, 8) +
               '<br />Longitude: '+ position.longitude.substr(0, 8)
    });

    //create listener for opening on click
    google.maps.event.addListener(RSApp.mark[id], 'click', function() {
      RSApp.infoWindow[id].open(RSApp.map,RSApp.mark[id]);
    });
  }
});