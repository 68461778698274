var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (color, contact, name, time) {
buf.push("<div class=\"checkbox col-md-4 col-xs-6\"><input type=\"checkbox\"" + (jade.attr("id", "" + (name) + "", true, false)) + (jade.attr("name", "" + (name) + "", true, false)) + (jade.attr("value", "" + (name) + "", true, false)) + " class=\"checkTracker\"/><label" + (jade.attr("for", "" + (name) + "", true, false)) + "><span" + (jade.attr("style", "background-color:" + (color) + ";", true, false)) + " class=\"colorTrace\"></span><span>" + (jade.escape(null == (jade_interp = ' ' + name + ' - ' + contact) ? "" : jade_interp)) + "<i>" + (jade.escape(null == (jade_interp = time.substr(time.search(' '))) ? "" : jade_interp)) + "</i></span></label></div>");}.call(this,"color" in locals_for_with?locals_for_with.color:typeof color!=="undefined"?color:undefined,"contact" in locals_for_with?locals_for_with.contact:typeof contact!=="undefined"?contact:undefined,"name" in locals_for_with?locals_for_with.name:typeof name!=="undefined"?name:undefined,"time" in locals_for_with?locals_for_with.time:typeof time!=="undefined"?time:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}