var __templateData = function template(locals) {
var buf = [];
var jade_mixins = {};
var jade_interp;
;var locals_for_with = (locals || {});(function (t) {
buf.push("<h4><span class=\"glyphicon glyphicon-globe\"></span>" + (jade.escape(null == (jade_interp = ' ' + t('GPS Tracking   ')) ? "" : jade_interp)) + "<!--button(type=\"button\" class=\"btn btn-default btn-sm\" data-toggle=\"modal\" data-target=\"#editDevicesModal\")= \"Renommer les traqueurs\"--></h4><div id=\"map-canvas\"></div><div id=\"editDevicesModal\" tabindex=\"-1\" role=\"dialog\" class=\"modal fade\"><div role=\"document\" class=\"modal-dialog\"><div class=\"modal-content\"><div class=\"modal-header\"><button type=\"button\" data-dismiss=\"modal\" aria-label=\"Close\" class=\"close\"><span aria-hidden=\"true\">" + (jade.escape(null == (jade_interp = 'X') ? "" : jade_interp)) + "</span></button><h4 class=\"modal-title\">" + (jade.escape(null == (jade_interp = 'Device Editor') ? "" : jade_interp)) + "</h4></div><div class=\"modal-body\"><form method=\"post\" role=\"form\" action=\"/setDevicesContact.php\"><div id=\"devicesFormNameEditor\"></div><div class=\"form-group\"><div class=\"btn-group text-center\"><button type=\"button\" data-dismiss=\"modal\" class=\"btn btn-danger\">" + (jade.escape(null == (jade_interp = 'Close') ? "" : jade_interp)) + "</button><button type=\"submit\" class=\"btn btn-success\">" + (jade.escape(null == (jade_interp = 'Ok') ? "" : jade_interp)) + "</button></div></div></form></div></div></div></div>");}.call(this,"t" in locals_for_with?locals_for_with.t:typeof t!=="undefined"?t:undefined));;return buf.join("");
};
if (typeof define === 'function' && define.amd) {
  define([], function() {
    return __templateData;
  });
} else if (typeof module === 'object' && module && module.exports) {
  module.exports = __templateData;
} else {
  __templateData;
}