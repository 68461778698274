"use strict";

var moment = require('moment');
var underscore = require('underscore');


module.exports = {
  //translate function
  t: function(str){
    return str;
    //return RSApp.polyglot.t(str);
  },
  moment: moment,
  _: underscore
};
