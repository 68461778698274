"use strict";

var _ = require('underscore');
var Backbone = require('backbone');
var Position = Backbone.Model.extend({
  url: function() {
    return false;
  },
  default:{
    name: '',
    contact: '',
    positions: []
  }
});

module.exports = Position;