"use strict";

var Backbone = require('backbone');
var _ = require('underscore');
var View = require('./view');
var elemView = require('./tracker_check_view');
var moment = require("moment");

module.exports = View.extend({
  className: 'panel panel-default',
  check: {},
  template: require('./templates/positions_select_view'),

  initialize: function(){
    _.bindAll(this, 'template', 'getRenderData', 'render', 'afterRender', 'addCheck', 'removeCheck');
    //if update collection call render
    RSApp.mediator.subscribe('showPosition', this.render);
    RSApp.mediator.subscribe('TrackerAddCheck', this.addCheck);
    RSApp.mediator.subscribe('TrackerRemoveCheck', this.removeCheck);

  },

  addCheck: function(id){
    this.check[id] = true;
  },

  removeCheck: function(id){
    delete this.check[id];
  },

  afterRender: function(){
    $('#control-map').prepend(this.el);

    this.collection = RSApp.Positions;

    var tpl;
    var now = moment();
    var that = this;

    $('#selectTracker').empty();

    this.collection.each(function(v, k) {
      if (v.attributes.positions.length>0) {
        //get the diff between now and the last tracking register
        var dt = moment(v.attributes.positions[0].fixtime);
        var diff = Math.abs(dt.diff(now, 'second'));

        //if last time is more than hour no display tracker
        if (diff<RSApp.maxTimeTracker) {
          //set color for info
          var bg = '#9a0000';

          if (diff < 300) {
            bg = 'green';
          }
          else if (diff > 301 && diff < 600) {
            bg = 'yellow';
          }
          else if (diff > 601 && diff < 3600) {
            bg = 'orange';
          }
          else if (diff > 3600 && diff < 14400) {
            bg = 'red';
          }
          else {
            bg = 'black';
          }

          v.attributes.color = bg;
          v.attributes.time = v.attributes.positions[0].fixtime;

          var tracker = new elemView({model: v});
          tracker.is_checked = (that.check[v.attributes.id]!==undefined);

          //add tracker to list
          $('#selectTracker').append(tracker.render().el);
        }
        else {
          if (RSApp.mark[v.attributes.id] !== undefined) {
            RSApp.mark[v.attributes.id].setMap(null);
          }
        }
      }
    });
  }
});