"use strict";

var _  = require('underscore');
var Backbone = require('backbone');
var Polyglot = require('polyglot');
var Router = require('./router');
var Mediator = require("mediator").Mediator;
require('offline');

var App =  {
  isOnline : true,
  maxTimeTracker: 3600,
  locale: 'fr',
  app: null,
  mapOptions: null,
  data: {},
  Positions: '',
  Files: {},
  Devices: {},
  poly: {},
  mark: {},
  infoWindow: {},
  polyglot: null,
  url : '',
  router: null,
  initialized: false,
  mediator: false,

  init: function() {
    if (this.initialized===true) {
      return this;
    }

    //init mediator
    this.mediator = new Mediator();

    this.subscribeEvent();

    //url for load data json
    this.url = 'https://gps.chlorobike.com/';

    //collection for positions
    var positions = require('../collections/positions');
    this.Positions = new positions();

    //files collection for list
    var files = require('../collections/files');
    this.Files = new files();

    //devices collection for list
    var devices = require('../collections/devices');
    this.Devices = new devices();

    this.mapOptions = {
      center: { lat: 45.0355, lng: 5.5624},
      zoom: 9,
      scaleControl:true,
      mapTypeId:google.maps.MapTypeId.HYBRID
    };



    var that = this;

      //lodad translate file
      // $.getJSON('http://events.velovertfestival.com/App/locales/' + this.locale + '/messages.json').complete(function(data, t) {

      that.mediator.publish('ready', {});

     // });

    return this;
  },

  //init the event listened
  subscribeEvent: function() {
    //app event
    this.mediator.subscribe('ready', this.ready);
    this.mediator.subscribe('display_msg', this.display_msg);
    this.mediator.subscribe('display_msg_delay', this.display_msg_delay);
    this.mediator.subscribe('showMask', this.showMask);
    this.mediator.subscribe('hideMask', this.hideMask);
    this.mediator.subscribe('showLoader', this.showLoader);
    this.mediator.subscribe('TestConnect', this.testConnect);
    //route event
    this.mediator.subscribe('backToHome', this.backToHome);
    this.mediator.subscribe('gotToStartTest', this.gotToStartTest);
    this.mediator.subscribe('gotToEndTest', this.gotToEndTest);
    //model event
    this.mediator.subscribe('syncFiles', this.syncFiles);
    this.mediator.subscribe('syncPositions', this.syncPositions);
    this.mediator.subscribe('syncDevices', this.syncDevices);

    return this;
  },

  //launch app
  ready: function(data) {
    //lang data
    this.data = data;
    //this.polyglot = new Polyglot({phrases: this.data, locale: this.locale});
    this.polyglot = {t: function(str){return str;}};
    this.router = new Router();
    //Backbone.emulateHTTP = true;
    Backbone.history.start();
    this.initialized = true;

    this.mediator.publish('TestConnect');
    this.mediator.publish('syncFiles');
    this.mediator.publish('syncPositions');
    this.mediator.publish('syncDevices');

    setInterval(function(){RSApp.mediator.publish('syncPositions');}, 5000);
    setInterval(function(){RSApp.mediator.publish('syncDevices');}, 60000);

    return this;
  },

  testConnect: function(){
    //test if online or not
    Offline.options = {
      //checkOnLoad: false,
      checks: {
        xhr: {
          url: this.url + 'testConnect.php'
        }
      }
    };

    var that = this;

    Offline.on("up", function(){
      that.hideMask();
      that.hideAlertOffline();
    });

    Offline.on("down", function(){
      that.showMask();
      that.showAlertOffline();
    });

    //check offline status every 3 minutes
    setInterval(function(){Offline.check()}, 10000);
  },

  display_msg_delay: function(type, message){
    var that = this;

    _.delay(function(){
      that.display_msg(type, message);
    }, 500);

    return this;
  },

  //display error
  display_msg: function(type, message){
    $('#alert_' + type + '_upload').html(RSApp.polyglot.t(message)).show().fadeOut(4000);
    //hide mask if display
    this.mediator.publish('hideMask');

    return this;
  },

  showAlertOffline: function(){
    $('body').append(
      '<div class="alert alert-danger" id="alertOffline" role="alert">' +
      RSApp.polyglot.t('You are offline, verify your connection') +
      '</div>'
    );

    return this;
  },

  hideAlertOffline: function(){
    $('#alertOffline').remove();
    return this;
  },

  //show the loader
  showLoader: function(empty){
    $('body').append('<div class="loader_container"><i class="fa fa-spinner fa-pulse"></i></div>');
    return this;
  },

  showMask: function(){
    $('body').append('<div id="maskOverlay"></div>');
    return this;
  },

  hideMask: function(){
    $('#maskOverlay').remove();
    $('.loader_container').remove();

    return this;
  },

  //fetch the tester by number plate
  syncFiles: function(next, arg){
    //if prod fetch is call or not
    var next = next || false;

    this.Files.fetch({
      reset: true,
      success: function(){
        RSApp.mediator.publish('showTrace');
      }
    });

    return this;
  },

  syncDevices: function(){
    this.Devices.fetch({reset: true,
      success: function(){
        RSApp.mediator.publish('showDevices');
      }
    });

    return this;
  },

  //sync the product
  syncPositions: function(){
    this.Positions.fetch({reset: true,
      success: function(){
        RSApp.mediator.publish('showPosition');
      }
    });

    return this;
  },

  backToHome : function(){
    this.router.navigate('', {trigger: true});

    return this;
  }
};

_.bindAll(
  App,
  'init',
  'ready',
  'display_msg',
  'showLoader',
  'showMask',
  'hideMask',
  'syncPositions',
  'backToHome',
  'syncFiles',
  'syncDevices',
  'display_msg_delay',
  'testConnect'
);

module.exports = App;